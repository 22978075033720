const plPL = {
  signIn: 'Zaloguj się',
  signUp: 'Zarejestruj się',
  signOut: 'Wyloguj się',
  logging: 'Logowanie',
  registration: 'Rejestracja',
  returnToHome: 'Powrót na stronę główną',
  char_one: 'znak',
  char_few: 'znaki',
  char_many: 'znaków',
  char_other: 'znak',
  cancel: 'Anuluj',
  confirm: 'Potwierdź',
  homePage: 'Strona główna',
  noData: 'Brak danych',
  pageNotFound: 'Strona nie istnieje.',
  intlDisplayNames: '{{value, displayNames}}',
  intlList: '{{value, list}}',
  intlDateTime: '{{value, datetime}}',
  intlDateTimeRange: '{{value, dateTimeRange}}',

  pageTitle: {
    defaultTitle: '',
    personalData: 'Dane osobowe',
  },

  genders: {
    female: 'Kobieta',
    male: 'Mężczyzna',
    other: 'Inna',
    notStated: 'Nie podano',
  },

  aria: {
    userMenu: 'menu użytkownika',
    languageButton: 'otwórz menu wyboru języka',
    back: 'cofnij',
    togglePassword: 'zmień widoczność hasła',
    hamburger: 'otwórz menu nawigacyjne',
    close: 'zamknij',
    edit: 'edytuj',
    delete: 'usuń',
    show: 'pokaż',
    clear: 'wyczyść',
  },

  form: {
    save: 'Zapisz',
    required: 'To pole jest wymagane',
    noWhitespace: 'Pole nie może być puste',
    pattern: 'Wprowadź prawidłowy {{what}}',
    betweenLen: 'Tekst powinien zawierać między {{min}}, a {{max}} $t(char, {"count": {{max}} })',
    minLen: 'Co najmniej {{value}} $t(char, {"count": {{value}} })',
    maxLen: 'Maksymalnie {{value}} $t(char, {"count": {{value}} })',
    min: 'Wartość nie może być mniejsza niż {{value}}',
    max: 'Wartość nie może być większa niż {{value}}',
    unicodeLettersAndSpaces: 'Tylko litery i spacje',
    telephone: 'Niepoprawny numer telefonu',
    email: 'Niepoprawny adres email',
    password: 'Nieprawidłowe hasło',
    passwordMatch: 'Hasła się nie zgadzają',
    personName: 'Nieprawidłowe znaki',
    dialCodeRequired: 'Podaj kod kierunkowy',
  },

  apiErrors: {
    default: 'Coś poszło nie tak, spróbuj ponownie.',
    unauthorized: 'Twoja sesja wygasła, zaloguj się ponownie.',
    forbidden: 'Nie posiadasz uprawnień do tego zasobu.',
    '1234': 'Testowy kod błędu',
  },

  passwordHints: {
    passwordShouldHas: 'Hasło powinno posiadać:',
    charLength: 'między {{min}}, a {{max}} $t(char, {"count": {{max}} })',
    lowercaseLetter: 'jedną małą literę',
    capitalLetter: 'jedną dużą literę',
    digit: 'jedną cyfrę',
    specialSign: 'jeden znak specjalny, np. @#!$%',
  },

  indexPage: {
    sorceryTab: 'Magik będzie skakał',
    artifactTab: 'Artefakty',
    searchPlaceholder: 'Wyszukaj',
    firstLineTitle: 'Zasady rekrutacji na studia na Politechnice Łódzkiej',
    personalData: 'Dane osobowe',
    photo: 'Zdjęcie legitymacyjne',
    messages: 'Komunikaty',
    accountActivation: 'Wyślij aplikację',
  },

  userIndexPage: {
    searchProcess: 'Wyszukaj mobilność',
  },

  authRoutes: {
    returnToLoginMethods: 'Powrót do metod logowania',
    returnToSignIn: 'Powrót do strony logowania',
  },

  loginPage: {
    chooseYourSituation: 'Wybierz swoją sytuację',
    tulStudent: 'Mam konto w systemie Politechniki Łódzkiej',
    erasmus: 'Mam konto w systemie Erasmus+',
    otherSituation: 'W żadnym z powyższych systemów nie posiadam konta',
    name: 'Imię',
    surname: 'Nazwisko',
    category: 'Określ swoją rolę',
    phone: 'Telefon',
    emailAddress: 'Email',
    password: 'Hasło',
    confirmPassword: 'Potwierdź hasło',
    forgotPassword: 'Nie pamiętasz hasła?',
    dontHaveAccount: 'Nie masz konta? Zarejestruj się',
    alreadyHaveAccount: 'Posiadasz konto? Zaloguj się',
  },

  emailConfirmationPage: {
    resendEmail: 'Wyślij email ponownie',
    emailSent: 'Email został ponownie wysłany.',
    accountRegistered:
      'Twoje konto zostało zarejestrowane. Na adres email podany podczas rejestracji otrzymasz link do potwierdzenia konta.',
  },

  authCallbackPage: {
    authenticating: 'Trwa uwierzytelnianie',
    redirect: 'Za chwilę nastąpi przekierowanie',
  },

  accountVerificationPage: {
    successfulActivation: 'Twój adres email został pomyślnie zweryfikowany.',
    errorMessage: 'Nie udało się zweryfikować adresu email.',
    waitForVerification: 'Trwa weryfikacja adresu email',
  },

  recoverPasswordPage: {
    recoverPassword: 'Odzyskiwanie hasła',
    emailSent: 'Email został wysłany. Aby zmienić hasło proszę kliknąć w link znajdujący się w treści wiadomości.',
    submitEmail: 'Wyślij email z linkiem',
  },

  resetPasswordPage: {
    resetPassword: 'Zmień hasło',
    savePassword: 'Zapisz nowe hasło',
    passwordChanged: 'Hasło zostało zmienione, możesz teraz zalogować się nowymi danymi',
  },
} as const;

Object.freeze(plPL);

export default plPL;
