import { keyframes, styled } from '@mui/material';

const animation1 = keyframes`
  0%,
  20% {
    box-shadow: 30px 0 0 3px, 10px 0 0 3px, -10px 0 0 3px, -30px 0 0 3px
  }
  60%,
  100% {
    box-shadow: 12px 0 0 3px, 14px 0 0 6px, -14px 0 0 6px, -12px 0 0 3px
  }
`;
const animation2 = keyframes`
  0%, 25% {
    transform: rotate(0)
  }
  50%, 100% {
    transform: rotate(.5turn)
  }
`;

export const LoadingIndicator = styled('div')(({ theme }) => {
  return {
    color: theme.palette.primary.main,
    width: 6,
    aspectRatio: 1,
    borderRadius: '50%',
    animation: `${animation1} .75s infinite linear alternate, ${animation2} 1.5s  infinite linear`,
  };
});
