import { type ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createTheme, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { MUILanguageContext, type MUILanguageContextProps } from './MUILanguageContext';
import { getMaterialLocales, supportedLanguages } from './localeUtils';

interface MUILanguageProviderProps {
  children?: ReactNode;
}

export const MUILanguageProvider = ({ children }: MUILanguageProviderProps): JSX.Element => {
  const { i18n } = useTranslation();
  const materialLocales = useMemo(
    () => getMaterialLocales(i18n.resolvedLanguage || i18n.language),
    [i18n.resolvedLanguage, i18n.language]
  );

  const contextValue: MUILanguageContextProps = useMemo(() => ({ materialLocales, supportedLanguages }), [materialLocales]);

  return (
    <MUILanguageContext.Provider value={contextValue}>
      <ThemeProvider theme={(theme) => createTheme(theme, ...Object.values(materialLocales))}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.resolvedLanguage}>
          {children}
        </LocalizationProvider>
      </ThemeProvider>
    </MUILanguageContext.Provider>
  );
};
