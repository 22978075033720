// fonts
import '@fontsource-variable/open-sans';

// i18-next config for internationalization
import '@/i18n';

// react-query
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryFallback } from '@/components/ErrorPages';

// providers
import { AppTheme } from '@/providers/AppTheme';
import { MUILanguageProvider } from '@/providers/MUILanguage';
import { ToastRenderer } from '@/components/Toast';
import { Router } from '@/Router';

import { themes } from '@/settings/themes';

const Root = (): JSX.Element => {
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <AppTheme themesSource={themes}>
        <MUILanguageProvider>
          <ToastRenderer />
          <Router />
        </MUILanguageProvider>
      </AppTheme>
    </ErrorBoundary>
  );
};

export default Root;
