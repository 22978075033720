import { Slide, ToastContainer } from 'react-toastify';
import { useTheme } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';

export const ToastRenderer = () => {
  const theme = useTheme();

  const style = {
    padding: '4px 16px',
    width: '100%',
    maxWidth: 500,
    '--toastify-color-info': theme.palette.info.main,
    '--toastify-color-success': theme.palette.success.main,
    '--toastify-color-warning': theme.palette.warning.main,
    '--toastify-color-error': theme.palette.error.main,
    '--toastify-text-color-info': theme.palette.info.contrastText,
    '--toastify-text-color-success': theme.palette.success.contrastText,
    '--toastify-text-color-warning': theme.palette.warning.contrastText,
    '--toastify-text-color-error': theme.palette.error.contrastText,
    '--toastify-font-family': theme.typography.fontFamily,
  };

  return (
    <ToastContainer
      position='bottom-center'
      newestOnTop
      limit={2}
      autoClose={5000}
      hideProgressBar
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      pauseOnHover
      draggable
      theme='colored'
      style={style}
      transition={Slide}
    />
  );
};
