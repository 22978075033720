import type { ReactNode } from 'react';

import { Stack, Typography } from '@mui/material';
import { Error as ErrorIcon } from '@mui/icons-material';

interface ErrorContentProps {
  message: ReactNode;
  title?: ReactNode;
  date?: ReactNode;
  noImage?: boolean;
}

export const ErrorContent = (props: ErrorContentProps) => {
  const { message, title, date, noImage = false } = props;
  return (
    <Stack direction='column' sx={{ gap: 2, alignItems: 'center', overflowWrap: 'anywhere' }}>
      {!noImage && <ErrorIcon sx={{ fontSize: 100 }} color='error' />}
      <Typography sx={{ fontSize: 20, fontWeight: 'bold', overflowWrap: 'inherit' }}>{message}</Typography>
      {!!title && <Typography sx={{ overflowWrap: 'inherit' }}>{title}</Typography>}
      {!!date && <Typography>{date}</Typography>}
    </Stack>
  );
};
