import type { ReactNode } from 'react';
import type { LinkProps as RouterLinkProps } from 'react-router';
import type { LinkProps as MuiLinkProps } from '@mui/material';

import { forwardRef } from 'react';

import { Link as RouterLink } from 'react-router';
import { Link as MuiLink } from '@mui/material';

const LinkBehavior = forwardRef<HTMLAnchorElement, RouterLinkProps>(function ForwardedLink(itemProps, ref) {
  return <RouterLink {...itemProps} ref={ref} role={undefined} />;
});

export interface LinkProps
  extends Omit<MuiLinkProps<typeof LinkBehavior, { component: typeof LinkBehavior }>, 'component' | 'children' | 'href'> {
  children?: ReactNode;
}

export const Link = (props: LinkProps): JSX.Element => {
  const { children, ...muiLinkProps } = props;

  return (
    <MuiLink component={LinkBehavior} {...muiLinkProps}>
      {children}
    </MuiLink>
  );
};
