import { isInstanceOfResponseErrorInterface, type ResponseErrorInterface } from '@/utilities/requestUtils/responseErrorInterface';

interface UserErrorInterface {
  errorCodes: string;
  message: string;
  date: string;
}

export const parseError = (err: unknown): UserErrorInterface => {
  if (isInstanceOfResponseErrorInterface(err)) {
    const error = err as unknown as ResponseErrorInterface;
    return {
      errorCodes: error.code,
      message: error.detail,
      date: error.timestamp,
    };
  } else if (err && typeof err === 'object' && 'errors' in err) {
    return err['errors'] as UserErrorInterface;
  } else {
    return {
      errorCodes: '',
      message: 'Unknown error 2',
      date: new Date().toDateString(),
    };
  }

  // isRouteErrorResponse();
};
