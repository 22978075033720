import type { ReactNode } from 'react';
import type { LinkProps as RouterLinkProps } from 'react-router';
import type { ButtonProps } from '@mui/material';

import { forwardRef } from 'react';

import { Link as RouterLink } from 'react-router';
import { Button } from '@mui/material';

const LinkBehavior = forwardRef<HTMLAnchorElement, RouterLinkProps>(function ForwardedLink(itemProps, ref) {
  return <RouterLink {...itemProps} ref={ref} role={undefined} />;
});

export interface LinkButtonProps
  extends Omit<ButtonProps<typeof LinkBehavior, { component: typeof LinkBehavior }>, 'component' | 'href' | 'children'> {
  children?: ReactNode;
}

export const LinkButton = (props: LinkButtonProps): JSX.Element => {
  const { children, ...restProps } = props;

  return (
    <Button {...restProps} component={LinkBehavior}>
      {children}
    </Button>
  );
};
