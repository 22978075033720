/* eslint-disable @typescript-eslint/no-explicit-any */
import { RouterProvider } from 'react-router/dom';
import { createBrowserRouter, type DataStrategyFunction } from 'react-router';
// import { authProvider } from '@/utilities/authProviders';
import { LoadingOverlay } from '@/components/LoadingOverlay';
import { NoMatch } from '@/routes/NoMatch';
import { routes, SUB_ROUTES } from '@/settings/router';

const dependenciesToInject = { authProvider: null };
Object.freeze(dependenciesToInject);

export type IContext = typeof dependenciesToInject;

const dataStrategy: DataStrategyFunction = async ({ matches }) => {
  // Run loaders in parallel with the `context` value
  const matchesToLoad = matches.filter((m) => m.shouldLoad);
  const results = await Promise.all(
    matchesToLoad.map((match) =>
      match.resolve((handler) => {
        return handler(dependenciesToInject);
      })
    )
  );
  return results.reduce(
    (acc, result, i) =>
      Object.assign(acc, {
        [matchesToLoad![i]!.route.id]: result,
      }),
    {}
  );
};

const router = createBrowserRouter(
  [
    // {
    //   path: routes.index(),
    //   hydrateFallbackElement: <LoadingOverlay isLoading />,
    //   lazy: () => import('@/routes/Unauthenticated'),
    //   children: [
    //     {
    //       index: true,
    //       lazy: () => import('@/routes/Index') as any,
    //     },
    //     {
    //       path: '*',
    //       Component: NoMatch,
    //     },
    //   ],
    // },
    {
      path: routes.index(),
      hydrateFallbackElement: <LoadingOverlay isLoading />,
      lazy: () => import('@/routes/MainLayout'),
      children: [
        {
          index: true,
          lazy: () => import('@/routes/Index') as any,
        },
        {
          lazy: () => import('@/routes/SubpageLayout'),
          children: [
            {
              path: SUB_ROUTES.PERSONAL_DATA,
              lazy: () => import('@/routes/PersonalData') as any,
            },
          ],
        },
        {
          path: '*',
          Component: NoMatch,
        },
      ],
    },
    {
      path: routes.user(),
      hydrateFallbackElement: <LoadingOverlay isLoading />,
      lazy: () => import('@/routes/Authenticated') as any,
      shouldRevalidate: () => false,
      children: [
        {
          index: true,
          lazy: () => import('@/routes/UserIndex') as any,
        },
        {
          path: '*',
          Component: NoMatch,
        },
      ],
    },
  ],
  {
    dataStrategy,
  }
);

export const Router = () => <RouterProvider router={router} />;
