import { type Components, createTheme } from '@mui/material';

// #region COLORS
const COLORS = {
  BLUE: '#394180',
  LIGHT_BG: '#fbfbfb',
  DEEP_JUNGLE_GREEN: '#115f59',
  ONYX: 'rgba(51, 56, 60, 0.8)',
  RUSTY_RED: '#d42f4d',
  TEXT_SECONDARY: 'rgba(0, 0, 0, 0.60)',
  CELTIC_BLUE: '#006aeb',
} as const;
// #endregion

// #region PALETTE
const PALETTE = {
  primary: {
    main: COLORS.BLUE,
  },
  secondary: {
    main: COLORS.DEEP_JUNGLE_GREEN,
  },
  info: {
    main: COLORS.CELTIC_BLUE,
  },
  error: {
    main: COLORS.RUSTY_RED,
  },
  text: {
    primary: COLORS.ONYX,
    secondary: COLORS.TEXT_SECONDARY,
  },
  background: { default: COLORS.LIGHT_BG, paper: '#fff' },
};
// #endregion

// #region TYPOGRAPHY
const TYPOGRAPHY = {
  fontFamily: ['"Open Sans Variable"', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  fontWeightLight: 400,
  fontWeightRegular: 500,
  fontWeightMedium: 700,
};
// #endregion

const DEFAULT_RADIUS = 4;
// #region OVERRIDES
const COMPONENTS: Components = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontWeight: TYPOGRAPHY.fontWeightRegular,
        '&:disabled': {
          backgroundColor: COLORS.ONYX,
        },
      },
      sizeLarge: {
        borderRadius: `${2 * DEFAULT_RADIUS}px`,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      rounded: {
        borderRadius: `${3 * DEFAULT_RADIUS}px`,
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      MenuProps: { slotProps: { paper: { style: { borderRadius: `${DEFAULT_RADIUS}px` } } } },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: '1rem',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontSize: '1rem',
        fontWeight: TYPOGRAPHY.fontWeightLight,
        color: COLORS.ONYX,
      },
    },
  },
};
// #endregion

// #region BREAKPOINTS
const BREAKPOINTS = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    fhd: 1920,
  },
};
// #endregion

// #region THEMES
// default theme
const defaultThemeOpts = {
  palette: PALETTE,
  typography: TYPOGRAPHY,
  components: COMPONENTS,
  breakpoints: BREAKPOINTS,
  shape: {
    borderRadius: DEFAULT_RADIUS,
  },
};

export const themes = { defaultTheme: createTheme(defaultThemeOpts) } as const;
// #endregion

// #region TS
export type CustomTheme = {
  [Key in keyof typeof defaultThemeOpts]: (typeof defaultThemeOpts)[Key];
};

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    fhd: true;
  }

  interface Theme extends CustomTheme {}

  interface ThemeOptions extends CustomTheme {}
}
// #endregion
