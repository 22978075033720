import type { ReactNode } from 'react';
import type { NavLinkProps as RouterNavLinkProps } from 'react-router';
import type { LinkProps as MuiLinkProps } from '@mui/material';

import { forwardRef } from 'react';

import { NavLink as RouterLink } from 'react-router';
import { Link as MuiLink } from '@mui/material';

const NavLinkBehavior = forwardRef<HTMLAnchorElement, RouterNavLinkProps>(function ForwardedLink(itemProps, ref) {
  return <RouterLink {...itemProps} ref={ref} role={undefined} />;
});

export interface NavLinkProps
  extends Omit<MuiLinkProps<typeof NavLinkBehavior, { component: typeof NavLinkBehavior }>, 'component' | 'children' | 'href'> {
  children?: ReactNode;
}

export const NavLink = (props: NavLinkProps): JSX.Element => {
  const { children, ...muiLinkProps } = props;

  return (
    <MuiLink component={NavLinkBehavior} {...muiLinkProps}>
      {children}
    </MuiLink>
  );
};
