import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import 'dayjs/locale/en-gb';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import plPLCommon from './locales/pl/common';
import enGBCommon from './locales/en/common';

import utcPlugin from 'dayjs/plugin/utc';
import customParseFormatPlugin from 'dayjs/plugin/customParseFormat';

dayjs.extend(utcPlugin);
dayjs.extend(customParseFormatPlugin);

export const i18n_NS = {
  DEFAULT: 'common',
} as const;

export const SUPPORTED_LNGS = {
  EN: 'en',
  EN_GB: 'en-gb',
  PL: 'pl',
} as const;

const resources = {
  [SUPPORTED_LNGS.EN]: {
    [i18n_NS.DEFAULT]: enGBCommon,
  },
  [SUPPORTED_LNGS.EN_GB]: {
    [i18n_NS.DEFAULT]: enGBCommon,
  },
  [SUPPORTED_LNGS.PL]: {
    [i18n_NS.DEFAULT]: plPLCommon,
  },
} as const;

i18next.on('languageChanged', () => {
  const langKey = i18next.resolvedLanguage ?? i18next.language;
  document.documentElement.setAttribute('lang', langKey);
  dayjs.locale(langKey.toLowerCase());
});

i18next
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lowerCaseLng: true,
    supportedLngs: Object.values(SUPPORTED_LNGS),
    fallbackLng: SUPPORTED_LNGS.EN_GB,
    defaultNS: i18n_NS.DEFAULT,
    ns: [i18n_NS.DEFAULT],
    resources,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    returnNull: false,
    debug: import.meta.env.VITE_DEV_I18N === 'true',
  })
  .catch((e) => console.error(e));

i18next.services.formatter?.addCached('displayNames', (lng, options) => {
  const formatter = new Intl.DisplayNames(lng, { ...options });
  return (val) => formatter.of(val) ?? '';
});

i18next.services.formatter?.addCached('dateTimeRange', (lng, options) => {
  const formatter = new Intl.DateTimeFormat(lng, { ...options });
  return (val) => formatter.formatRange(val[0], val[1]);
});

export default i18next;

declare module 'i18next' {
  interface CustomTypeOptions {
    // custom namespace type if you changed it
    defaultNS: typeof i18n_NS.DEFAULT;
    // custom resources type
    resources: (typeof resources)[typeof SUPPORTED_LNGS.EN];
    returnNull: false;
  }
}
