import type { ReactElement, ReactNode } from 'react';
import { forwardRef } from 'react';
import type { LinkProps as RouterLinkProps } from 'react-router';
import { Link as RouterLink } from 'react-router';
import type { ListItemButtonProps } from '@mui/material';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

const LinkBehavior = forwardRef<HTMLAnchorElement, RouterLinkProps>(function ForwardedLink(itemProps, ref) {
  return <RouterLink {...itemProps} ref={ref} role={undefined} />;
});

export interface ListItemLinkProps
  extends Omit<ListItemButtonProps<typeof LinkBehavior, { component: typeof LinkBehavior }>, 'component' | 'href' | 'children'> {
  icon?: ReactElement;
  primary?: ReactNode;
  secondary?: ReactNode;
  disableTypography?: boolean;
  children?: ReactNode;
  inset?: boolean;
}

export const ListItemLink = (props: ListItemLinkProps): JSX.Element => {
  const { icon, primary, secondary, disableTypography = false, children, inset, sx, ...listBtnProps } = props;

  return (
    <ListItemButton
      component={LinkBehavior}
      sx={[{ borderRadius: 'inherit' }, ...(Array.isArray(sx) ? sx : [sx])]}
      {...listBtnProps}
    >
      {!!icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText inset={inset} primary={primary} secondary={secondary} disableTypography={disableTypography} />
      {children}
    </ListItemButton>
  );
};
