import type { MaterialLocales, SupportedLanguages } from './localeUtils';

import { createContext } from 'react';

export interface MUILanguageContextProps {
  materialLocales: MaterialLocales;
  supportedLanguages: SupportedLanguages;
}

export const MUILanguageContext = createContext<MUILanguageContextProps | undefined>(undefined);
MUILanguageContext.displayName = 'MUILanguageContext';
