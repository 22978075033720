import { useEffect, useRef, useState } from 'react';
import { Backdrop, type BackdropProps } from '@mui/material';

import { LoadingIndicator } from './LoadingIndicator';

interface LoadingOverlayProps extends Omit<BackdropProps, 'open' | 'children' | 'ref'> {
  isLoading?: boolean;
  delay?: number;
}

export const LoadingOverlay = (props: LoadingOverlayProps): JSX.Element => {
  const { isLoading = false, delay = 0, sx, ...restProps } = props;

  const delayHandler = useRef<ReturnType<typeof setTimeout>>();
  const [loading, setLoading] = useState(delay > 0 ? false : isLoading);

  useEffect(() => {
    if (delay === 0) {
      setLoading(isLoading);
      return;
    }

    if (isLoading) {
      delayHandler.current = setTimeout(() => setLoading(true), delay);
    } else {
      clearTimeout(delayHandler.current);
      setLoading(false);
    }

    return () => {
      clearTimeout(delayHandler.current);
    };
  }, [delay, isLoading]);

  return (
    <Backdrop
      open={loading}
      aria-busy={loading}
      data-testid='backdropWrapper'
      sx={[
        (theme) => ({
          position: 'absolute',
          borderRadius: 'inherit',
          zIndex: theme.zIndex.modal - 1,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...restProps}
    >
      <LoadingIndicator role='progressbar' />
    </Backdrop>
  );
};
