import type { ReactNode } from 'react';
import { Stack, type StackProps } from '@mui/material';

import { ErrorContent } from './ErrorContent';
import { parseError } from '@/utilities/parsers/errorParser';

interface ErrorPageProps extends StackProps {
  error: unknown;
  children?: ReactNode;
}

export const ErrorPage = (props: ErrorPageProps) => {
  const { children, error, sx, ...restProps } = props;

  const { errorCodes, message, date } = parseError(error);

  return (
    <Stack sx={[{ gap: 4, alignItems: 'center' }, ...(Array.isArray(sx) ? sx : [sx])]} {...restProps}>
      <ErrorContent message={message} title={errorCodes} date={date} />
      {children}
    </Stack>
  );
};
