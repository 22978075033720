import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { HomePageLinkBtn } from '@/components/Link';

export const NoMatch = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography component='h1' fontSize={200} color='secondary'>
        404
      </Typography>
      <Typography component='p' variant='h4'>
        {t('pageNotFound')}
      </Typography>
      <HomePageLinkBtn color='primary' sx={{ mt: 3 }} />
    </Box>
  );
};
