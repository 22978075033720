import type { Localization } from '@mui/material/locale';
import { enUS, plPL } from '@mui/material/locale';
import { enUS as enUSPickers, plPL as plPLPickers } from '@mui/x-date-pickers/locales';
import { SUPPORTED_LNGS } from '@/i18n';

export const supportedLanguages = [
  { label: 'Polski', value: [SUPPORTED_LNGS.PL] },
  { label: 'English', value: [SUPPORTED_LNGS.EN_GB] },
] as const;
export type SupportedLanguages = typeof supportedLanguages;

export interface MaterialLocales {
  core: Localization;
  pickers: typeof plPLPickers;
}

export const getMaterialLocales = (localizationKey: string): MaterialLocales => {
  switch (localizationKey) {
    case SUPPORTED_LNGS.PL:
      return { core: plPL, pickers: plPLPickers };
    case SUPPORTED_LNGS.EN:
    case SUPPORTED_LNGS.EN_GB:
    default:
      return { core: enUS, pickers: enUSPickers };
  }
};
