export const ROUTE_PARAMS = {
  ID: 'id',
} as const;

export const SUB_ROUTES = {
  PERSONAL_DATA: 'personal-data',
  USER: 'user',
  AUTH: 'auth',
  SIGN_IN: 'signin',
  AUTH_CALLBACK: 'auth-callback',
  SIGN_UP: 'signup',
  EMAIL_CONFIRMATION: 'email-confirmation',
  RECOVER_PASSWORD: 'recover-password',
  RESET_PASSWORD: 'reset-password',
  SELECT_PROFILE: 'select-profile',
  ACCOUNT_VERIFICATION: 'account-verification',
} as const;

export const routes = {
  index: () => '/' as const,

  personalData: () => `${routes.index()}${SUB_ROUTES.PERSONAL_DATA}` as const,

  user: () => `${routes.index()}${SUB_ROUTES.USER}` as const,

  auth: () => `${routes.index()}${SUB_ROUTES.AUTH}` as const,
  authCallback: () => `${routes.auth()}/${SUB_ROUTES.AUTH_CALLBACK}` as const,
  signIn: () => `${routes.auth()}/${SUB_ROUTES.SIGN_IN}` as const,
  signUp: () => `${routes.auth()}/${SUB_ROUTES.SIGN_UP}` as const,
  recoverPassword: () => `${routes.auth()}/${SUB_ROUTES.RECOVER_PASSWORD}` as const,
  emailConfirmation: () => `${routes.auth()}/${SUB_ROUTES.EMAIL_CONFIRMATION}` as const,
  resetPassword: () => `${routes.auth()}/${SUB_ROUTES.RESET_PASSWORD}` as const,
} as const;
