import { type ReactNode } from 'react';
import { CssBaseline, type Theme, ThemeProvider as MUIThemeProvider } from '@mui/material';

interface ThemeSourceItem extends Partial<Theme> {}

interface ThemesSource {
  defaultTheme: ThemeSourceItem;

  [id: string]: ThemeSourceItem;
}

interface AppThemeProps {
  themesSource: ThemesSource;
  children: ReactNode;
}

export const AppTheme = ({ themesSource, children }: AppThemeProps): JSX.Element => {
  return (
    <MUIThemeProvider theme={themesSource['defaultTheme']}>
      <CssBaseline enableColorScheme />
      {children}
    </MUIThemeProvider>
  );
};
