import type { LinkButtonProps } from './LinkButton';

import { useTranslation } from 'react-i18next';

import { LinkButton } from './LinkButton';

import { routes } from '@/settings/router';

type HomePageLinkBtnProps = Omit<LinkButtonProps, 'to' | 'children'>;

export const HomePageLinkBtn = (props: HomePageLinkBtnProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <LinkButton to={routes.index()} color='secondary' variant='contained' {...props}>
      {t('returnToHome')}
    </LinkButton>
  );
};
