const enGB = {
  signIn: 'Sign in',
  signUp: 'Sign up',
  signOut: 'Sign out',
  logging: 'Log in',
  registration: 'Sign up',
  returnToHome: 'Return to homepage',
  char_one: 'character',
  char_other: 'characters',
  cancel: 'Cancel',
  confirm: 'Confirm',
  homePage: 'Homepage',
  noData: 'No data',
  pageNotFound: 'Page not found.',
  intlDisplayNames: '{{value, displayNames}}',
  intlList: '{{value, list}}',
  intlDateTime: '{{value, datetime}}',
  intlDateTimeRange: '{{value, dateTimeRange}}',

  pageTitle: {
    defaultTitle: '',
    personalData: 'Dane osobowe',
  },

  genders: {
    female: 'Female',
    male: 'Male',
    other: 'Other',
    notStated: 'Not stated',
  },

  aria: {
    userMenu: 'user menu',
    languageButton: 'open language menu',
    back: 'back',
    togglePassword: 'toggle password visibility',
    hamburger: 'open navigation menu',
    close: 'close',
    edit: 'edit',
    delete: 'delete',
    show: 'show',
    clear: 'clear',
  },

  form: {
    save: 'Save',
    required: 'This field is required',
    noWhitespace: 'The field cannot be empty',
    pattern: 'Please enter a valid {{what}}',
    betweenLen: 'The text needs to be between {{min}} and {{max}} $t(char, {"count": {{max}} }) long',
    minLen: 'At least {{value}} $t(char, {"count": {{value}} })',
    maxLen: 'No more than {{value}} $t(char, {"count": {{value}} })',
    min: 'Value cannot be less than {{value}}',
    max: 'Value cannot be greater than {{value}}',
    unicodeLettersAndSpaces: 'Only letters and spaces',
    telephone: 'Incorrect phone number',
    email: 'Incorrect email address',
    password: 'Invalid password',
    passwordMatch: 'Passwords should match',
    personName: 'Incorrect characters',
    dialCodeRequired: 'Enter dial code',
  },

  apiErrors: {
    default: 'Something went wrong, please try again.',
    unauthorized: 'Your session has expired, please log in again.',
    forbidden: 'Access to this resource on the server is denied.',
    '1234': 'english test',
  },

  passwordHints: {
    passwordShouldHas: 'The password should have:',
    charLength: 'between {{min}} and {{max}} $t(char, {"count": {{max}} })',
    lowercaseLetter: 'one lower case letter',
    capitalLetter: 'one capital letter',
    digit: 'one digit',
    specialSign: 'one special character, e.g. @#!$%',
  },

  indexPage: {
    sorceryTab: 'Sorcery',
    artifactTab: 'Artifact',
    searchPlaceholder: 'Search',
    firstLineTitle: 'Zasady rekrutacji na studia na Politechnice Łódzkiej',
    personalData: 'Dane osobowe',
    photo: 'Zdjęcie legitymacyjne',
    messages: 'Komunikaty',
    accountActivation: 'Wyślij aplikację',
  },

  authRoutes: {
    returnToLoginMethods: 'Go back to login options',
    returnToSignIn: 'Return to login page',
  },

  loginPage: {
    chooseYourSituation: 'Choose your situation',
    tulStudent: 'I have an Lodz University of Technology account',
    erasmus: 'I have an Erasmus+ account',
    otherSituation: 'I do not have an account in any of the above systems',
    name: 'Name',
    surname: 'Surname',
    category: 'Define your role',
    phone: 'Phone',
    emailAddress: 'Email',
    password: 'Password',
    confirmPassword: 'Confirm password',
    forgotPassword: 'Forgot password?',
    dontHaveAccount: "Don't have an account? Sign up",
    alreadyHaveAccount: 'Already have an account? Sign in',
  },

  emailConfirmationPage: {
    resendEmail: 'Resend email',
    emailSent: 'The email has been resent.',
    accountRegistered:
      'Your account has been registered. You will receive a link to confirm your account to the email address you provided during registration.',
  },

  authCallbackPage: {
    authenticating: 'Authentication in progress',
    redirect: 'A redirect will take place in a moment',
  },

  accountVerificationPage: {
    successfulActivation: 'Your email has been confirmed.',
    errorMessage: 'The email address could not be verified.',
    waitForVerification: 'Email address verification in progress',
  },

  recoverPasswordPage: {
    recoverPassword: 'Recover password',
    emailSent: 'An email has been sent. To change your password please click on the link in the body of the message.',
    submitEmail: 'Email me a recovery link',
  },

  resetPasswordPage: {
    resetPassword: 'Change password',
    savePassword: 'Change password',
    passwordChanged: 'Your password has been changed, you can now log in with your new credentials.',
  },
} as const;

Object.freeze(enGB);

export default enGB;
